import React, { useState } from 'react';
import CalculateForm from '../Components/CalculateForm';
import ResultDisplay from '../Components/ResultDisplay';

function Calculate() {
  const [result, setResult] = useState(null);

  const handleResult = (data) => {
    setResult(data);
  };

  return (
    <div>
      <h1>คำนวณเลขทะเบียนผล</h1>
      <CalculateForm onResult={handleResult} />
      {result && <ResultDisplay result={result} />}
    </div>
  );
}

export default Calculate;
