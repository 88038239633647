import React from "react";
import Doctor from "../Assets/thaid.png";
import SolutionStep from "./SolutionStep";
import "../Styles/Step.css";

function Step() {
  return (
    <div className="step-section" id="step">
      <div className="step-image-content">
        <img src={Doctor} alt="Doctor Group" className="step-image1" />
      </div>

      <div className="step-text-content">
        <h3 className="step-title">
          <span>การจองเลขทะเบียน</span>
        </h3>
        <p className="step-description">
          ตั้งแต่วันที่ 1 มิถุนายน 2566 เป็นต้นไป การจองหมายเลขทะเบียนรถยนต์จะต้อง ยืนยันตัวบุคคล ทุกครั้งก่อนการจอง
          ผ่านแอปพลิเคชัน ThaiD ของกรมการปกครองเท่านั้น.
          .
        </p>

        <h4 className="step-text-title">สิ่งที่ต้องเตรียมความพร้อม</h4>

        <SolutionStep
          title="แอปพลิเคชัน ThaiD ( ไทยดี )"
          description="เจ้าของรถยนต์ / ผู้เช่าซื้อ ลงทะเบียนติดตั้ง แอปพลิเคชัน ThaiD และลงทะเบียนตัวบัตรประชาชนของ เจ้าของรถยนต์ / ผู้เช่าซื้อ **ห้ามใช้ แอปพลิเคชัน ThaiD ด้วยชื่ออื่น มิเช่นนั้นจะทำให้ไม่สามารถยื่นจดทะเบียนได้"
        />

        <SolutionStep
          title="ข้อมูลรถยนต์ ( แชสซี ) หมายเลขตัวถัง"
          description="ยี่ห้อรถของเจ้าของรถ เช่น MERCEDES BENZ และ ( แชสซี ) หมายเลขตัวถัง 17 หลัก เช่น WDB124xxxxxxx1599"
        />

        <SolutionStep
          title="หมายเลขที่ต้องการจอง"
          description="แนะนำ ควรมีเลขสำรอง เตรียมไว้ซัก 3 - 5 เลข เนื่องจาก ณ ปัจจุบัน การเลือกเลขทะเบียนรถนั้น ขึ้นอยู่กับความเชื่อ และความชอบของบุคคล ดังนั้น หากเราต้องการเลขแบบนี้ คนอื่น ก็ต้องการเช่นเดียวกัน"
        />
      </div>
    </div>
  );
}

export default Step;
