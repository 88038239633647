import React from "react";
import InformationCard from "./InformationCard";
import CalculateLicensePlate from "./CalculateLicensePlate";
import { faReceipt, faBolt, faBars } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <CalculateLicensePlate />
      <div className="info-title-content">
        <h3 className="info-title">
          <span>บริการของเรา</span>
        </h3>
        <p className="info-description">
          เราดำเนินการเกี่ยวกับรับจองเลขทะเบียนรถ รถเก๋ง / รถกระบะ 4 ประตู (ไม่เกิน 7 ที่นั่ง) , รถตู้ (เกิน 7 ที่นั่ง)
          รถกระบะ , และรถมอเตอร์ไซค์
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="รับจองเลขทะเบียนรถ"
          description="จองเลขทะเบียนหมวดใหม่ ไม่ต้องเสียเวลานั่งพิมพ์ข้อมูลเอง ทางทีมงานดูและให้ครบ จบทุกขั้นตอน."
          icon={faBolt}
        />

        <InformationCard
          title="เลขทะเบียนหมวดเก่า"
          description="จัดหาเลขทะเบียนหมวดเก่า ที่ไม่มีตัวเลขนำหน้า หรือป้ายประมูลทะเบียนรถเลขสวย."
          icon={faReceipt}
        />

        <InformationCard
          title="บริการด้านอื่นๆ"
          description="สลับเลขทะเบียนรถยนต์ / โอน - รับรถยนต์ รถมอเตอร์ไซค์ / ด้านภาษี พ.ร.บ. / และอื่นๆ."
          icon={faBars}
        />
      </div>
    </div>
  );
}

export default Info;
