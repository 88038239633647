import React, { useState, useEffect, useCallback, useRef } from "react";
import html2canvas from "html2canvas";
import "../Styles/CalculateLicensePlate.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import plateImage from '../Assets/plate.png';

const starData = [
  {
      sum: 5,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก เป็นดาวศุภเคราะห์ ให้ผลในด้านการศึกษา ครู ผู้พิพากษา หมอ วัด โรงเรียน ศีลธรรม ความซื่อสัตย์ ความรับผิดชอบ'
  },
  {
      sum: 6,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก มีเสน่ห์รวยเก่ง (ทะเบียนรถ) ทะเบียนรถผลรวม 6 นี้เป็นธาตุน้ำ เป็นดาวศุภเคราะห์ ให้ผลด้านการเงิน ความรัก ความสมหวัง มีเสน่ห์ มีโลกส่วนตัว เจ้าสำราญเสมอ เงินทองไหลมาเทมา มีเสน่ห์ ชื่อเสียง มีความสำเร็จที่งดงาม'
  },
  {
      sum: 7,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี ทะเบียนรถผลรวม 7 จะมีผลทางลบมากกว่าทางบวกจะนำความยุ่งยากให้แก่ชีวิต มีความไม่มั่นคงในชีวิต โรคภัยไข้เจ็บ ป่วยง่าย เป็นหวัดบ่อย ปวดหัวตัวร้อยเสมอ ทำอะไรก็ติดขัดไปหมด มีการสูญเสียเสมอ ไม่สมหวังเรื่องความรัก ถึงจะมีสติปัญญาและความอดทน แต่ก็ไม่มีความมั่นคงในชีวิต'
  },
  {
      sum: 8,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี ทะเบียนรถผลรวม 8 ประมุขของเหล่าพาล นักเลงทุกชนิด ความมัวเมา ความเสเพล ดาวแห่งการเสี่ยงโชคและโชคลาภ โหราศาสตร์ มัวเมาอบายมุข ไม่เกรงกลัวใคร ชอบของมึนเมา เที่ยวกลางคืน ไปในทางอบายมุข เสริมดวงอาชีพเทาๆดำๆ รวยเร็ว'
  },
  {
      sum: 9,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก เพราะได้พลังจากสิ่งที่มองไม่เห็นมาช่วยดูแลคุ้มครอง ช่วยทำให้กิจการงานใดๆประสบผลสำเร็จ บางครั้งมีอุบัติเหตุก็ทำให้รอดพ้นอย่างไม่น่าเชื่อ มีอายุยืนมั่นคง มีความคิดรวดเร็วว่องไว เลขนี้ทำให้อยู่สบาย เรื่องราวชีวิตราบรื่น ได้โชคลาภแบบไม่คาดฝัน'
  },
  {
      sum: 10,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง เป็นคนจริงจังซีเรียสทุกเรื่อง เชื่อมั่นในตัวเองสูง ไม่ชอบอยู่ใต้บังคับใคร เป็นคนแข็งกร้าว ชอบเป็นผู้นำ ยอมหักไม่ยอมงอ ไม่ค่อยประนีประนอม แต่กล้าตัดสินใจ กล้าได้กล้าเสีย ในชีวิตมักจะมีอุปสรรคบ้าง ผู้ใช้ผลรวมนี้ควรมีสตินึกถึงใจเขาใจเรารับฟังผู้อื่น'
  },

  {
      sum: 11,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง ทำอะไรก็เด่นดี เด่นดัง แต่ทำคุณคนไม่ขึ้น ลักษณะต้นดี ปลายร้าย ใช้ชีวิตไม่ควรประมาท ให้ระวังการถูกทรยศหักหลังจากผู้อื่น และเพศตรงข้าม เป็นคนเข้าใจยาก มีบุคลิกสองอย่างขัดแย้งกัน การแสดงออกภายนอกอ่อนไหว เรียบร้อย เป็นระเบียบ แต่ภายในหัวแข็ง ดื้อไม่ยอมใคร ชีวิตต้องต่อสู้ดิ้นรนฟันฝ่าอุปสรรคด้วยแข้งตัวเองตลอด เป็นคนที่มีความทะเยอทะยานสูงมาก'
  },
  {
      sum: 12,
      star: 1,
      result: 'มีผลรวมในระดับ พอใช้ เป็นคนที่มีอารมณ์ขัดแย้งกันในตัวเอง เป็นคนมี 2 บุคลิกคือ บางครั้งดื้อรั้น มุทะลุ ดุดัน บางครั้งก็อ่อนโยน ว่าง่ายเชื่อฟัง เวลาที่อยากเอาชนะใครจะต้องเอาชนะให้ได้ไม่ยอมคน ชอบการเอาชนะไม่ยอมสยบใครง่ายๆ ความใจร้อนทำให้เกิดอุบัติเหตุ ชอบใจอ่อนระวังจะโดนหลอกได้ง่าย เรื่องความรักทุ่มเทสุดตัวมักผิดหวัง'
  },
  {
      sum: 13,
      star: 5,
      result: 'มีผลรวมในระดับ ไม่ดี  จะเกิดทั้งเรื่องดีและเรื่องร้าย ชีวิตมักจะมีการเปลี่ยนแปลงเร็ว ชีวิตจะมีทุกข์ อุปสรรค ล้มเหลว กลายเป็นคนอมทุกข์ ชีวิตสมรสไม่แน่นอน คือไม่ได้แต่งงาน หรือถ้าได้แต่งงาน ครอบครัวจะมีปัญหาแตกร้าว ถ้าผู้ชายจะมีเรื่องชู้สาว หรือรักสามเส้า ระวังอุบัติเหตุหนัก มีโอกาสได้ลาภลอยแต่ก็ต้องถูกหมดสิ้นไปอย่างรวดเร็ว ต้องระวังอุบัติเหตุจากการเดินทาง'
  },
  {
      sum: 14,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ถือเป็นผลรวมมงคลที่ได้รับความนิยมอย่างมาก เป็นผู้ฉลาดประสบความสำเร็จอย่างยิ่งใหญ่และมั่นคง มีความรู้สติปัญญาเหนือคนอื่นๆ ไหวพริบดีไม่เจอปัญหา ส่งเสริมให้มีความรุ่งโรจน์อย่างมั่นคง ชอบไขว่คว้าค้นหาความก้าวหน้าให้กับชีวิต และยังมีสิ่งศักดิ์สิทธิ์ให้ความคุ้มครอง ชอบเดินทางเจราเด่น ถ้าเกี่ยวข้องกับต่างประเทศจะยิ่งช่วยส่งเสริม มีโอกาสก้าวหน้าได้อย่างรวดเร็วทางด้านหน้าที่การงาน เป็นคนสมองเฉียบแหลมว่องไว กล้าคิดกล้าทำหล้าตัดสินใจ มีชื่อเสียงกว้างไกล'
  },

  {
      sum: 15,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ถือว่าเป็นผลรวมที่ดีมาก โชคดีตลอด ไม่ว่าจะเดินทางไปไหนก็มีแต่โชคดี หาที่จอดง่าย มีคนรัก แม้ผิดก็มองเป็นถูกด้วยความมีเสน่ห์ของคุณ ได้รับความรักความเอ็นดูแและได้รับการสนับสนุนจากคนทุกระดับ ไปไหนมาไหนก็มีแต่คนรัก เป็นคนมีเสน่ห์ มีอารมณ์ดี น่าดึงดูดใจเพศตรงข้าม มีมนุษย์สัมพันธ์ดี มีเพื่อนมาก และอาจจะมีโอกาสมีคนรักหลายๆคนได้ เพราะความอัธยาศัยดีของคุณ มีผู้หลักผู้ใหญ่ให้การสนับสนุนเสมอ คุณมีดีที่คำพูด วาจาเป็นเลิศ เข้ากับคนได้ทุกระดับ อ่านใจคนเก่ง มีสติปัญญาดี รู้จักพลิกแพลงเรื่องต่างๆด้วยคำพูด แก้ผิดเป็นถูกได้อย่างไม่น่าเชื่อ เรื่องเงินๆทอง จะสามารถหาได้โดยง่าย '
  },
  {
      sum: 16,
      star: 4,
      result: 'มีผลรวมในระดับ ดี ชีวิตดีอย่าน่าใจหาย ชะตาชีวิตจะส่งเสริมให้ชีวิตพบกับความสำเร็จอย่างผาดโผน มีชื่อเสียง มีอำนาจแบบชนิดคาดไม่ถึง จะได้ลาภหรือตำแหน่งใหญ่โต มีอำนาจ มีลูกน้องมาก ด้วยตำแหน่งที่ได้มาที่มักจะไปขัดแข้งขัดขาผู้อื่นเสมอ และมักจะมีคนไม่ชอบมาก จึงอาจจะโดนเลื่อยขาเก้าอี้ได้โดยง่ายเป็นคนที่มีความร้อนรุ่มในใจเสมอ มีอุปสรรคเยอะ จะทำอะไรสำเร็จต้องเหนื่อยอย่างมากก่อนจึงจะสำเร็จ'
  },
  {
      sum: 17,
      star: 2,
      result: 'มีผลรวมในระดับ พอใช้ เป็นผู้มีปัญญาดี มีโอกาสมีความสำเร็จสูง แต่ก็ต้องเรียกว่าเหนื่อยมากๆกว่าจะสำเร็จหรือลองผิดลองถูกกันหลายครั้ง เป็นคนเข้าใจอะไรได้ง่าย สามารถหยั่งรู้เหตุการณ์ล่วงหน้าได้อย่างรวดเร็ว ช่วงที่ชีวิตประสบความสำเร็จอาจจะพบกับอุปสรรคหรือสิ่งที่ขัดขวางผลสำเร็จ มักจะถูกผู้ใหญ่กดดันและให้โทษเสมอๆ ทำให้ชีวิตขึ้นๆลงๆ ไม่แน่นอน เมื่อครั้งรับตำแหน่งใหญ่โตจะโดนทำให้ตกจากตำแหน่งได้ หรือไม่ก็มักจะถูกแย่งตำแหน่ง หน้าที่การงานมักจะไม่ยั่งยืนถาวร มีมิตรที่ดี เมื่อจะก้าวหน้ามักมีอุปสรรคเสมอ'
  },
  {
      sum: 18,
      star: 2,
      result: 'มีผลรวมในระดับ พอใช้ ชีวิตขึ้นลงไม่แน่นอน ชีวิตอยู่ไม่ติดที่ มีการโยกย้าย เปลี่ยนแปลงอยู่บ่อยๆ ไม่เหมาะกับอาชีพที่ทำงานอยู่กับที่ ควรจะเป็นงานที่เดินทาง งานที่ไม่อยู่กับที่ ควรจะทำอะไรอย่างรอบคอบ พร้อมรับการเปลี่ยนแปลงอยู่ตลอด ความสำเร็จจะมีเป็นช่วงๆ ชะตาจะขึ้นสุดลงสุด ไม่แน่นอน ขึ้นอย่าหลงลงอย่าท้อ ถ้าเป็นผู้หญิงอาจตกเป็นเมียน้อย หย่าร้าง รักสามเส้า'
  },
  {
      sum: 19,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก เหมาะสำหรับผู้ชาย ชีวิตก็จะประสบความสำเร็จ ได้รับการอุปการะจากผู้ใหญ่ หรือผู้มีอำนาจให้การช่วยเหลือ ได้รับเกียรติอย่างสูงในสังคม ส่งผลดีให้กับชีวิต ประสบความสำเร็จที่ยิ่งใหญ่ ชีวิตมักประสบความสำเร็จจากผู้อื่นที่ใหญ่กว่า มีสติปัญญาดี ฉลาดหลักแหลม จะได้ตำแหน่งใหญ่โตในหน้าที่การงาน และมีความเชี่ยวชาญพิเศษทางการศึกษา ไม่อับจน มีคนช่วยเหลือ ส่งผลดีให้กับอนาคต พบกับความสำเร็จที่ยิ่งใหญ่ เหมาะสำหรับผู้ชายมากกว่าผู้หญิง'
  },
  {
      sum: 20,
      star: 4,
      result: 'มีผลรวมในระดับ ดี เป็นคนคิดการใหญ่มักทำอะไรเกินตัวอยู่เสมอ ชอบทำอะไรเองชอบทำอะไรเกินตัว มักได้รับความช่วยเหลือสนับสนุนเป็นอย่างดี เหมาะกับผู้ชายและผู้หญิง แต่ถ้าเป็นผู้หญิงมักมีคนรักหรือคู่ครองที่อายุมากกว่า อาจจะเป็นบ้านเล็กได้ แต่ถ้าใช้ทะเบียนผลรวมนี้จะช่วยเสริมได้รับการดูแลอุปถัมภ์จากผู้ใหญ่ หรือคนรักเป็นอย่างดี ระวังเรื่องคนอิจฉานินทา นำพาชีวิตประสบความสำเร็จเกินตัว เป็นคนมักมีความมุ่งมั่นสูง การจะสำเร็จต้องอาศัยผู้อื่น เช่น เพื่อนร่วมงาน ลูกน้อง มาช่วยประกอบกันจึงจะสำเร็จ เปลี่ยนแปลงไม่ชอบหยุดนิ่งอยู่กับที่ ควรจะทำบุญเสริมบารมีบ่อยๆจึงจะช่วยเสริมบารมีได้'
  },
  {
      sum: 21,
      star: 2,
      result: 'มีผลรวมในระดับ พอใช้ ชีวิตจะมีเหตุแตกหักกลางคัน ทำให้เกิดมุมหักเหและตกจากตำแหน่งหน้าที่การงาน จะมีเรื่องของความทุกข์ ผิดหวัง ชีวิตสมรสไม่แน่นอน มีความไม่แน่นอนของชีวิตและอนาคต บางครั้งชีวิตอาจจะส่งผลให้พบกับความรุ่งโรจน์ แต่ในขณะเดียวกันก็จะล้มอย่างฉับพลัน เช่น ธุรกิจ หน้าที่การงาน ความรัก ขึ้นๆ ลงๆ เหมือนสมหวังแต่กลับผิดหวัง ขึ้นๆลงๆ หาความแน่นอนไม่ได้ มักจะมีสิ่งไม่คาดคิดเกิดแบบเฉียบพลันบ่อยๆเช่น การงานที่กำลังรุ่งเรืองต้องมามีเรื่องล้มไม่รู้ตัว อนาคตมัวหมอง อะไรที่เหมือนจะดีกลับต้องพลาดไปกลายเป็นร้าย ชีวิตต้องเหนื่อย ดูแลผู้อื่นจนไม่ได้สนใจตัวเอง'
  },
  {
      sum: 22,
      star: 4,
      result: 'มีผลรวมในระดับ ดี เป็นคนมีลางสังหรณ์ดี เป็นนักคิดนักฝัน มั่นใจตัวเองสูง ไม่ชอบอยู่กับที่ อยู่ไม่ติดบ้าน ชอบทำอะไรแปลกแหวกแนว รวนเร หวั่นไหวง่าย เป็นคนเพ้อฝันมีอารมณ์อ่อนไหว เป็นคนละเอียดอ่อน เพ้อฝัน หาความแน่นอนไม่ค่อยได้ ความคิดเห็นแย้งกับคนอื่นเสมอ ส่วนที่ดีคือ มีดวงโชคลาภ อาจจะร่ำรวยหรือเด่นดังอย่างไม่คาดคิด จะได้ดีมีสุขเพราะโชคดีมากกว่าที่จะสร้างมาด้วยตนเอง'
  },
  {
      sum: 23,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก เหมาะสำหรับผู้หญิง ทะเบียนนี้จะมีแต่คนรักคนหลง มีคนเข้าหาเยอะเพราะมีเสน่ห์เมตตามหานิยมอย่างมาก เป็นผู้หญิงจะดีมากมีตัวเลือกและคนคุยมาก ส่วนผู้ชายก็เช่นกันแต่คนที่เข้ามานั้นอาจจะมีทั้งดีและร้าย อาจจะหวังผลประโยชน์อะไรบางอย่าง เหมาะกับคนเจ้าชู้ เป็นคนเข้มแข็ง อดทน ต่อสู้อุปสรรคต่างๆเพื่อให้สำเร็จ ชอบเอาชนะคนอื่นไม่ยอมคน เป็นเลขดีด้านชู้สาว เป็นคนเจ้าชู้ที่ถือเรื่องความรักเป็นใหญ่ ต้องบริหารเสน่ห์ให้ดีเพื่อไม่ให้กระทบกับงาน เด่นด้านเสน่ห์ความรักสนุก มีผู้ใหญ่ให้การช่วยเหลือสนับสนุนอย่างดี โชคดีอย่างมากในเรื่องความรัก ส่งเสริมให้ชีวิตประสบความสำเร็จ'
  },
  {
      sum: 24,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ทะเบียนรถผลรวมนี้ ถือว่าเป็นผลรวมที่ดีมากๆ ประสบความสำเร็จเด่นมากเรื่องการงาน การเงิน ร่ำรวยจากการงานที่ทำเป็นอย่างดี มีคนรักมาก ผู้ใหญ่หรือลูกน้องสนับสนุนตลอด ช่วยเสริมด้านบารมีเป็นอย่างมาก ชีวิตจะเจริญก้าวหน้าอย่างต่อเนื่อง เป็นคนมีเสน่ห์ ดึงดูดใจให้คนชอบ รักใคร่ สุภาพโรแมนติก มีโชคดีเกี่ยวกับการเรื่องเงินทองตลอด จะสามารถหามาได้โดยง่าย และเป็นดาวการเงิน มีเพื่อนและบริวารมาก เพื่อนฝูงมักให้การอุปถัมภ์ ค้ำจุน เป็นที่รักของคนทั่วไป จะประสบความสำเร็จ เหมาะกับอาชีพ นักปกครอง บริหาร นักการค้าขาย การเจรจาต่อรองต่างๆ โดยรวมส่งผลดีได้เกือบทุกอาชีพเลย เข้ากับใครได้ง่าย และได้รับความสุขในด้านความรัก'
  },
  {
      sum: 25,
      star: 4,
      result: 'มีผลรวมในระดับ ดี คิดอะไรเป็นเงินเป็นทองไปหมด สร้างได้ง่ายด้วยตนเอง แต่อาจเจอปัญหาบ้างก็จะแก้ได้ด้วยตนเองสำเร็จไปได้ทุกที ฐานะมั่นคง ไม่ลำบาก ชีวิตประสบความสำเร็จ ดาวจันทร์และดาวพฤหัสดีหนุนนำชีวิตให้มีความก้าวหน้ารุ่งเรือง เป็นคนปราดเปรื่องชอบศึกษาหาความรู้ เข้ากับผู้ใหญ่เป็น หาเงินเก่ง สุขุมรอบคอบ จะมั่งมีศรีสุข ฐานะมั่นคง มีทรัพย์สิน ถ้าอยากร่ำรวยมากๆ อาจจะต้องมุ่งมั่นตั้งใจ แล้วจะสำเร็จเป็นอย่างดี'
  },
  {
      sum: 26,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง มีเมตตาต่อผู้อื่น ชีวิตอาจประสบความสำเร็จได้ด้วยตนเอง งานขึ้นๆลงๆ ความรักไม่แน่นอน ต้องทำความดีตลอดไม่เหมาะกับธุรกิจสีเทา ควรทำอะไรด้วยตนเองมากหุ้นกับคนอื่น เพิ่งตนเองมากกว่าผู้อื่น ชีวิตเหมือนน่าประสบความสำเร็จ แต่จะไม่ยั่งยืนถาวร มักจะเกิดจากการแนะนำผิดๆ จากเพื่อนฝูง และหุ้นส่วน ต้องใส่ใจในการคบหา ระวังเรื่องการคบค้าสมาคมกับใคร'
  },
  {
      sum: 27,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง เต็มไปด้วยพลังที่ไม่ยอมแพ้ ความมุ่งมั่นเพื่อชัยชนะ เชื่อมั่นในตนเองสูง จนบางครั้งจะเสียงานเพราะไม่ฟังใคร มักจะมีความคิดเห็นเป็นของตนเอง ชีวิตมักจะพบกับความกดดันอย่างรุนแรง มีทุกข์ลาภ ชีวิตจะพบกับความสำเร็จได้ สิ่งที่เป็นความสำเร็จเป็นผลมากจากความตั้งใจ ดวงชะตายังคงมีความผิดหวัง ความเศร้าอยู่บ้าง ขับรถต้องระวังอาจจะใจร้อนได้ ควรใจเย็นมีน้ำใจ'
  },
  {
      sum: 28,
      star: 4,
      result: 'มีผลรวมในระดับ ดี กล้าได้กล้าเสีย ชอบเสี่ยงโชคและมีดวงการพนันค่อนข้างดี ขึ้นๆลงๆบ้าง ชอบหาประสบการณ์ใหม่ๆ ชอบเดินทางรับทรัพย์ ถ้าเป็นโสดหรือพ่อหม้ายแม่หม้าย จะได้เจอคู่อาจมีลูกติดแต่เป็นคู่ที่ดี เป็นคนที่มีความรับผิดชอบน้อย เป็นคนเชื่อมั่นในตัวเอง แต่ไม่ไว้ใจผู้อื่นในชีวิตจึงเต็มไปด้วยความขัดแย้ง ชอบริเริ่มแต่ไม่ชอบรับผิดชอบ ชีวิตลำบากไม่ประสบความสำเร็จ ผิดหวังในเรื่องความรัก ชอบทำงานใหญ่โดยไม่ไตร่ตรอง'
  },
  {
      sum: 29,
      star: 4,
      result: 'มีผลรวมในระดับ ดี มักเป็นคนใจแข็ง มั่นใจในตัวเองสูงไม่ค่อยรับฟังผู้อื่น อาจดูอ่อนโยนภายนอกแต่ภายในก้าวร้าว ดื้อรั้น ยึดติดกับบางเรื่อง รักความสบาย รสนิยมดี ชีวิตไม่ลำบาก แต่มีเรื่องทุกข์ใจ ความรุ่งเรืองไม่ยั่งยืน มีการแข่งขันทางธุรกิจสูง ถูกกดดันกลั่นแกล้ง อิจฉาริษยา ความรักไม่ประสบความสำเร็จ มีความคิดรุนแรง ต่อต้านสังคม ความคิดริเริ่มสร้างสรรค์ดีเยี่ยม'
  },
  {
      sum: 30,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี เจออุปสรรคอยู่ตลอดเวลา หน้าที่การงานไม่ก้าวหน้า ความรุ่งโรจน์ไม่ยั่งยืน ความรักไม่สมหวัง เจ็บป่วยบ่อยหรือได้รับเกิดอุบัติเหตุได้โดยง่าย ต้องระวังเรื่องอุบัติเหตุเป็นอย่างมาก'
  },
  {
      sum: 31,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี ชีวิตมักจะมีการเปลี่ยนแปลงเร็ว ชีวิตจะมีทุกข์ อุปสรรค ล้มเหลว กลายเป็นคนอมทุกข์ ชีวิตสมรสไม่แน่นอน ครอบครัวจะมีปัญหาแตกร้าว ถ้าผู้ชายจะมีเรื่องชู้สาว สุขภาพไม่แข็งแรงป่วยบ่อย หรือมีอุบัติเหตุไม่คาดฝัน ไม่มีดวงการพนัน ประสบความสำเร็จยาก มีอุปสรรคมากมายมาขวางกั้น กว่าจะถึงจุดหมายปลายทางสำเร็จ ไม่มีความมั่นคงในชีวิต'
  },
  {
      sum: 32,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก เหมาะสำหรับผู้หญิง ทะเบียนนี้จะมีแต่คนรักคนหลง มีคนเข้าหาเยอะเพราะมีเสน่ห์เมตตามหานิยมอย่างมาก เป็นผู้หญิงจะดีมากมีตัวเลือกและคนคุยมาก ส่วนผู้ชายก็เช่นกันแต่คนที่เข้ามานั้นอาจจะมีทั้งดีและร้าย อาจจะหวังผลประโยชน์อะไรบางอย่าง เหมาะกับคนเจ้าชู้ เป็นคนเข้มแข็ง อดทน ต่อสู้อุปสรรคต่างๆเพื่อให้สำเร็จ ชอบเอาชนะคนอื่นไม่ยอมคน เป็นเลขดีด้านชู้สาว เป็นคนเจ้าชู้ที่ถือเรื่องความรักเป็นใหญ่ ต้องบริหารเสน่ห์ให้ดีเพื่อไม่ให้กระทบกับงาน เด่นด้านเสน่ห์ความรักสนุก มีผู้ใหญ่ให้การช่วยเหลือสนับสนุนอย่างดี โชคดีอย่างมากในเรื่องความรัก ส่งเสริมให้ชีวิตประสบความสำเร็จ'
  },
  {
      sum: 33,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง เอาแต่ใจตัวเอง ขี้หงุดหงิด ความรักรุนแรง เร่าร้อน เป็นคนขยันและมานะพากเพียร อดทนสูง อารมณ์วู่วาม ไม่มีเหตุผล ทำอะไรมักเอาแต่ใจตนเอง เป็นคนเลือดร้อน ชอบมีปากเสียงกับผู้อื่นได้ง่าย มีเรื่องการขัดวาจากันเสมอ ระวังอุบัติเหตุรุนแรง ชะตารุ่งเรืองดีแต่ไม่ยั่งยืนถาวร'
  },
  {
      sum: 34,
      star: 4,
      result: 'มีผลรวมในระดับ ดี เป็นคนรอบรู้เรื่องราวหลากหลายใฝ่ศึกษา ชอบค้นคว้าหาความรู้ ชอบอ่านหนังสือ ดูสิ่งที่มีสาระ มีปฏิภาณดี ตัดสินใจไว ว่องไวรวดเร็วเป็นบุคคลกล้าหาญ กล้าพูดกล้าทำ ฉลาดคิด เป็นคนเปิดเผย ทำให้ชีวิตประสบความสำเร็จได้อย่างดี'
  },
  {
      sum: 35,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง เป็นคนกระฉับกระเฉงคล่องงานรอบตัว มีพรสวรรค์เข้ากับคนได้ทุกระดับชั้นชีวิต ระวังเรื่องเชื่อคนง่าย ผู้ใหญ่จะให้ทั้งคุณและโทษ มีทั้งคนที่หลอกใช้เพื่อประโยชน์กับคนที่ช่วยเหลืออย่างจริงจัง บั้นปลายจะประสบความสำเร็จดีจะมีผู้ใหญ่ให้การอุปถัมภ์ค้ำชู การตัดสินใจต่างๆควรจะรอบคอบ อาจถูกคนเอารัดเอาเปรียบถูกเบียดบังเอาความดีความชอบ ความล้มเหลวในชีวิตจะมาจากคนอื่น ระวังอย่าเชื่อใจใครง่ายๆ'
  },
  {
      sum: 36,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก พลังความรักและความสำเร็จ เป็นเลขดีมาก ผู้อยู่ใต้อิทธิพลเลขนี้ มักมีความสุขสมหวัง ทั้งด้านการงานและความรัก การงานมีโอกาสก้าวหน้าโดดเด่น ทำงานในตำแหน่งใหญ่โต ถ้าเป็นธุรกิจก็มักจะเจริญรุ่งเรืองดี หาทรัพย์เก่ง มักจะประสบความสำเร็จร่ำรวย การเรียนรู้การศึกษาดี และจะเด่นมากในเรื่องความรัก มักจะมีโชคดีเรื่องความรัก เป็นที่นิยมชมชอบ ถ้าเป็นเลขชื่อของแบรนด์หรือกิจการก็มักจะได้รับความนิยม ได้รับความร่วมมือจากคนรอบข้างอย่างดี เป็นผู้ที่สร้างความประทับใจให้คนรอบข้างเสมอ มีมิตรสหายมาก'
  },
  {
      sum: 37,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี เป็นเลขร้ายเป็นกำลังของดาวบาปเคราะห์ หมายถึงการทะเลาะวิวาทขัดแย้ง วิบัติทุกข์ทรมานจากโรคร้าย ระวังการผ่าตัดอุบัติเหตุ ความเสียหายที่จะเกิดขึ้นอย่างรุนแรง ชีวิตสมรสไม่แน่นอน ชีวิตต้องเริ่มใหม่อยู่เสมอมักเกี่ยวข้องกับความตาย ชีวิตมีความข่มขืนผิดหวัง อมทุกข์ บริวารนำเรื่องเดือดร้อนมาให้ มีหนี้สินอย่างท่วมท้น ไม่ชอบให้ใครมาบังคับ ไม่ชอบให้ใครมาคอย จู้จี้ขี้บ่น ชอบแบกปัญหาภาระของผู้อื่น สติปัญญาดี เป็นคนรู้ง่ายแตกฉาน ระวังโรคหัวใจ ความดันโลหิต อัมพาต อัมพฤกษ์'
  },
  {
      sum: 38,
      star: 4,
      result: 'มีผลรวมในระดับ ดี  เลข ๓๘ ส่งอิทธิพลให้เจ้าชะตา มักมีอุปนิสัยใจกล้า กล้าได้กล้าเสีย เป็นคนคิดไวทำไว แก้ปัญหาได้ดีทันสถานการณ์ มักทำอะไรคล่องแคล่วว่องไว มีความสามารถสูง หาเงินเก่ง งานที่ชอบทำ คืองานที่ต้องมีความท้าทาย ไม่ว่าปัญหาอะไรก็จะผ่านไปได้'
  },
  {
      sum: 39,
      star: 4,
      result: 'มีผลรวมในระดับ ดี ความกดดัน ดาวอังคารเลข ๓ และ ดาวเกตุเลข ๙ มาอยู่คู่กัน มักส่งผลให้เจ้าชะตามักกดดันตนเอง ไขว่คว้าหาโอกาสไปสู่ความสำเร็จที่อยู่สูงกว่า มีความขยันหมั่นเพียร มุ่งมั่นมุมานะไปสู่เป้าหมายในชีวิต ชอบพิชิตชัยชนะในการแข่งขัน มีความกล้าหาญ กล้าคิดกล้าทำ มักจะมีมิตรสหายมาก และมิตรสนิทจะให้การเกื้อกูลได้เป็นอย่างดี'
  },
  {
      sum: 40,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ปฏิภาณไหวพริบนำพาสู่ความรุ่งโรจน์ เป็นเลขดี อิทธิพลของเลขนี้ มักส่งผลให้เจ้าชะตาเป็นผู้มีปัญญาเฉลียวฉลาด ปฏิภาณไหวพริบล้ำเลิศ แก้ปัญหาเฉพาะหน้าได้ดี ชอบการประดิษฐ์คิดค้นสิ่งใหม่ๆ เป็นนักคิดนักฝัน มักมีแนวคิดและไอเดียที่โดดเด่น จินตนาการก้าวไกล โดดเด่นในสังคม มีวาทะศิลป์ในการโน้มน้าวจิตใจคนอื่น ชอบเดินทางท่องเที่ยว เก็บเงินเก่งบริหารการเงินเป็น เหมาะกับงานวางแผนทางธุรกิจ งานด้านการตลาด หรืองานที่ต้องติดต่อสื่อสารกับคนมากๆ'
  },
  {
      sum: 41,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก มักส่งผลให้เจ้าชะตามีพลังความทะเยอะทะยานเต็มเปี่ยม นำพาสู่ความสำเร็จรุ่งโรจน์ มีสติปัญญาดี ปฏิภาณไหวพริบเป็นเลิศ สมองปราดเปรื่อง ส่งผลให้การงานประสบความสำเร็จอย่างงดงาม มักมีคนคอยช่วยเหลือสนับสนุนเสมอ มีวาจาเป็นอาวุธ มีวาทะศิลป์ในการติดต่อสื่อสารกับผู้อื่น ใครได้ฟังมักจะคล้อยตาม เข้ากับผู้อื่นได้เป็นอย่างดี มีเพื่อนมาก มักติดต่อหรือเกี่ยวข้องกับต่างประเทศ เป็นตัวเลขที่มีพลังของมหาจักรวาลหนุนชะตาอยู่ เป็นเลขแห่งความสำเร็จ มักจะนำพาชีวิตไปสู่ความสำเร็จที่ยิ่งใหญ่ ทำอาชีพการงานใดก็มักจะเจริญก้าวหน้าได้อย่างรวดเร็ว คิดทำการค้าก็ร่ำรวย ดวงมีทรัพย์มีลาภยศ หากหมั่นทำบุญจะยิ่งช่วยเสริมบารมีให้รุ่งเรืองยิ่งๆขึ้นไปอีก'
  },
  {
      sum: 42,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ความสำเร็จ และการสนับสนุน เป็นเลขดีมาก อิทธิพลของเลขจะส่งผลให้เจ้าชะตามักมีเสน่ห์ดึงดูดใจ เป็นบุคคลน่าสนใจน่าคบหา มีมิตรสหายมาก มักเป็นที่รักแก่บุคคลทั้งหลาย มักมีเพื่อนฝูงหรือกัลยาณมิตรให้การสนับสนุน อุปถัมภ์ ค้ำจุน บุคลิกสุภาพ นุ่มนวล อ่อนโยนโรแมนติก สติปัญญาเฉียบแหลม มีวาทะศิลป์ในการติดต่อสื่อสาร โน้มน้าวจิดใจคนอื่นได้ดี จิตวิทยาสูง สนใจใฝ่เรียนรู้ ชีวิตมักจะประสบความสำเร็จเป็นอย่างดี มีชื่อเสียงเป็นที่รู้จักในสังคม เป็นนักพูดชั้นดี เหมาะกับอาชีพเกือบทุกประเภท มักมีโชคดีเกี่ยวกับการเรื่องเงินๆทองๆ จะสามารถหามาได้โดยง่าย เป็นหมายเลขที่ดีที่สุดเลขหนึ่ง'
  },
  {
      sum: 43,
      star: 4,
      result: 'มีผลรวมในระดับ ดี เป็นผู้มีปฏิภาณไหวพริบดี แก้ไขปัญหาเก่ง มีมนุษยสัมพันธ์ดี เข้ากับคนง่าย แต่มีข้อด้อยคือ เป็นคนคิดมาก ไม่ค่อยไว้ใจใคร เลข ๔ กับเลข ๓ บวกกันได้เลข ๗ ดาวเสาร์ ชีวิตจึงมักเหน็ดเหนื่อย ต้องต่อสู้ฝ่าฟันกับอุปสรรคที่มีเข้ามา แต่จะประสบผลสำเร็จในที่สุด'
  },
  {
      sum: 44,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก หนุนนำให้ชีวิตมีความเจริญรุ่งโรจน์ดี โดดเด่นเรื่องมีฝีปากดี มีวาทศิลป์ พูดจาเป็นที่น่าเชื่อถือและประทับใจ ฉลาดหลักแหลม สติปัญญาดี มักมีความรู้การศึกษาดี รู้เท่าทันคนทันโลก ผู้ใหญ่ให้การสนับสนุนเสมอ จึงมักประสบความสำเร็จด้านหน้าที่การงาน ชีวิตช่วงใดมีอุปสรรคก็มักผ่านพ้นไปได้ด้วยดี'
  },
  {
      sum: 45,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ความสำเร็จและความมั่นคง เป็นเลขดีมาก อิทธิพลของเลขนี้ มักจะส่งผลให้เจ้าชะตามีสติปัญญาฉลาดหลักแหลม ปฏิภาณไหวพริบดีมาก เรียนรู้เร็ว ประสบความสำเร็จในการเล่าเรียนทุกศาสตร์ มีความรู้รอบตัวมาก รู้ทั้งวิชาการทางโลกและทางธรรม ฉลาดทันคน ทันโลก และยังมีเสน่ห์ที่คำพูด ปรับตัวเข้ากับสังคมได้ทุกที่ ประสบความสำเร็จอย่างมั่นคง ทั้งด้านการงานและความรัก การเงินดีมาก มักร่ำรวย ฐานะมั่นคง มักมีผู้คอยสนับสนุน ทำการสิ่งใดมักมีผู้ใหญ่ให้การช่วยเหลือตลอดไม่ตกอับ ความรักราบรื่น มีสิ่งศักดิ์สิทธิ์ปกป้องคุ้มครอง มักแคล้วคลาดจากอุบัติเหตุร้ายแรง เลขนี้ส่งอิทธิพลต่อความมั่นคงในชีวิตดีมาก'
  },
  {
      sum: 46,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ชีวิตมักจะมีโชคดีอยู่บ่อยๆ สติปัญญาแจ่มใส เป็นคนมีรสนิยมสูง มีเสน่ห์ ไม่ชอบหยุดนิ่งอยู่กับที่ ชอบแสวงหาสิ่งใหม่ๆ ชีวิตพบกับความสำเร็จ มีความสุข สะดวกสบาย ส่งผลให้ชีวิตดีพอสมควร'
  },
  {
      sum: 47,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง เป็นเลขมีทั้งดีและเสียเลขหนึ่ง อิทธิพลของเลข ๔ ทำให้มีความระทมขมขื่นแอบแฝงอยู่ มักจะเป็นคนใจอ่อน และเชื่อใจคนง่าย ต้องระวังการหลงผิดจะทำลายอนาคตอันรุ่งเรืองเสียหาย แต่บุคคลหมายเลข ๔๗ เป็นคนขยันหมั่นเพียรดี มักได้รับความสำเร็จจากความพยายามอันเหนื่อยยาก กว่าจะพบเป้าหมาย ต้องใช้ความอดทน และความพยายามมาก มักจะเป็นคนใจอ่อนหลงเชื่อคนง่าย ระวังจะถูกหลอก แต่ก็เป็นคนที่มีความขยันหมั่นเพียรดีกว่าจะพบกับความสำเร็จต้องใช้ความพยายาม และอดทนอย่างสูง ระวังโรคเกี่ยวกับช่องท้อง ระบบย่อยอาหาร ระบบขับถ่าย ริดสีดวงทวาร'
  },
  {
      sum: 48,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี ชีวิตมักจะประพฤติผิดพลาดและได้รับอันตราย จะถูกชักจูงหว่านล้อม จากพวกหลงผิดให้ไปเข้าพวกเข้าฝูง ทำให้ตกอยู่ในอำนาจแห่งความหลงกระทำแต่สิ่งที่ไม่ถูกต้องอยู่เสมอ หลงแสง สี เสียง การพนัน สิ่งเสพติด ชีวิตจะได้รับความเดือดร้อน สูญเสียอย่างใหญ่หลวง ระวังอุบัติเหตุ คดีความ ถูกขุมขัง ความไม่แน่นอนของชีวิต และอนาคต'
  },
  {
      sum: 49,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี เป็นเลขร้ายเป็นกำลังของดาวบาปเคราะห์ หมายถึงผ่าตัดอุบัติเหตุ ความเสียหายที่จะเกิดขึ้นอย่างรุนแรง ชีวิตสมรสไม่แน่นอน ครอบครัวแตกแยก มีมุมหักเหอยู่ตลอดเวลา สุภาพสตรีจะถูกสามีกดดันกลั่นแกล้งชีวิตต้องเริ่มใหม่อยู่เสมอมักเกี่ยวข้องกับความตาย ชีวิตมีความข่มขืนผิดหวัง อมทุกข์ มีอุปสรรค อับโชค ระวังการผ่าตัด โรคนิ่ว โรคไต กระเพาะปัสสาวะ'
  },
  {
      sum: 50,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก จะทำให้ชีวิตประสพผลสำเร็จอย่างมั่นคง สติปัญญาดี ความจำแม่นยำ จะนำพาไปสู่ความสำเร็จที่ยิ่งใหญ่ มีความสุขุมล้ำลึก ชอบไขว่คว้าค้นหาความก้าวหน้าให้กับชีวิต และยังมีสิ่งศักดิ์สิทธิ์ให้ความคุ้มครอง มีโอกาสไปเกี่ยวข้องกับต่างประเทศทุกรูปแบบเช่น ศึกษาต่างประเทศ เที่ยวต่างประเทศ อยู่ต่างประเทศ คบหากับคนต่างประเทศ สนใจธรรมมะ รักความยุติธรรม อาชีพที่เหมาะคือประชาสัมพันธ์ พบกับคนแปลกหน้าถึงจะดี'
  },
  {
      sum: 51,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ทำให้มีเสน่ห์และเป็นคู่มิตร ไม่ว่าจะทำอะไรก็จะมีคนมาช่วยเหลือสนับสนุนตลอดเวลา เลข ๑ และ ๕ รวมกันได้ ๖ ดาวศุภโชค โภคทรัพย์ เป็นหมายเลขที่โชคดีมาก จะได้รับพรอันประเสร็จ เป็นบุคคลที่มีความโชคดีเกี่ยวกับการเงิน เรื่องเงินๆทอง จะสามารถหาได้โดยง่าย'
  },
  {
      sum: 51,
      star: 4,
      result: 'มีผลรวมในระดับ ดี เป็นดาวศุภเคราะห์ทั้งคู่ ดาวจันทร์และดาวพฤหัสดีหนุนนำชีวิตให้มีความก้าวหน้ารุ่งเรือง เป็นคนปราดเปรื่องชอบศึกษาหาความรู้ เข้ากับผู้ใหญ่เป็น หาเงินเก่ง สุขุมรอบคอบ จะมั่งมีศรีสุข ฐานะมั่นคง มีทรัพย์สิน เงินทองไม่ลำบาก ดวงความรักดี ดูเผินๆ ชีวิตน่าจะประสพความสำเร็จเป็นอย่างดี ข้อเสียจะทำอะไรกว่าจะประสพความสำเร็จต้องผ่านอุปสรรคและการต่อสู้อย่างเหน็ดเหนื่อย เพราะ บวกกันได้ ๗ ซึ่งเป็นดาวเสาร์ ชีวิตต้องอยู่กับปัญหา มีอุปสรรค อับโชคและเป็นดาวปัญหา มีปัญหาให้ต้องแก้ไขอยู่ตลอดเวลาแต่ก็ผ่านไปได้ด้วยดี'
  },
  {
      sum: 52,
      star: 4,
      result: 'มีผลรวมในระดับ ปานกลาง เชื่อใจคนง่ายเกินไป จะทำให้ถูกหลอก ตกอยู่ในสภาพแวดล้อมที่ไม่ดี ถูกคนเอารัดเอาเปรียบถูกเบียดบังเอาความดีความชอบ ความล้มเหลวในชีวิตจะมาจากคนอื่น ระวังอย่าเชื่อใจใครง่ายๆ จะต้องอยู่ในสภาพแวดล้อมที่ไม่ดี ความล้มเหลวของชีวิตจะมาจากพรรคพวกเพื่อนฝูง หุ้นส่วน จะมีปัญหารักสามเส้า กำพร้า พ่อแม่ ไม่ได้อยู่ด้วยกัน พ่อแม่เลิกลากัน เป็นดาวขี้โรค ต้องหาหมดอยู่ตลอดเวลา โรคตับ ถุงน้ำดี ไมเกรน เจ็บกระดูกตามข้อ'
  },
  {
      sum: 54,
      star: 3,
      result: 'มีผลรวมในระดับ ดี ชีวิตมักจะมีโชคดีอยู่เนืองๆ มักจะประสบโชคดีอย่างไม่คาดฝันอยู่บ่อยๆ สติปัญญาดี จะได้รับความร่วมมือจากทุกวงการเป็นอย่างดี จะได้ลาภก้อนโตจากการเสี่ยงทุกรูปแบบ มีความมั่นคงในชีวิต'
  },
  {
      sum: 55,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ดาวพฤหัสบดีสองดวงส่งเสริมหนุนนำให้อยู่สุขสบายไม่ลำบาก ชะตาชีวิตสดใสรุ่งโรจน์ ฐานะความเป็นอยู่มั่นคง มีความเจริญก้าวหน้าเป็นลำดับ ประสบความสำเร็จในชีวิต บ่งถึงจะทำให้ชีวิตก้าวหน้าและประสบความสำเร็จอย่างมั่นคง มีความเที่ยงตรง รักในคุณงามความดี ชอบทำบุญทำทาน มักเลื่อมใสต่อสิ่งศักดิ์สิทธ์ ชอบเดินทางไกล เกี่ยวข้องกับต่างประเทศ เป็นเลขที่ให้คุณกับชีวิตเลขหนึ่ง'
  },
  {
      sum: 56,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ส่งเสริมให้มีความเจริญรุ่งเรืองและมีความสุข คนเลขนี้เป็นคนที่มีวิชาความรู้กว้างขวาง จะพูดจาหรือทำสิ่งใดก็มีแผนการดี มีเป้าหมายชัดเจน ผู้คนยอมรับนับถือกันมาก ช่วยเหลือคนตกทุกข์ได้ยากอย่างจริงใจ มักได้เกี่ยวพันกับผู้คนในแวดวงหรูหรา ชะตาชีวิตโดดเด่น มีลาภยศปรากฏ มีผู้คนชื่นชมรักใคร่ มักจะมีโชคในเรื่องรักๆ ใคร่ๆ ได้ใช้ชีวิตอย่างเพลิดเพลิน ไม่ต้องต่อสู้ดิ้นรนมาก ความสำเร็จในชีวิตมาจากความเก่งกาจสามารถของตน และเป็นสิ่งที่จะนำอำนาจบารมีมาให้'
  },
  {
      sum: 57,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง อาจตกจากตำแหน่งหน้าที่การงาน การถูกใส่ร้ายป้ายสี การถูกคุมขัง หน้าที่การงานจะไม่ยั่งยืนถาวร หลงมัวเมาในทางที่ผิดๆ หลงมัวเมาไปในทางที่ผิดๆ ต้องตกอยู่ในสิ่งแวดล้อมที่ไม่ดี ถูกคนเอารัดเอาเปรียบถูดเบียดบังเอาความดีความชอบ จะมีปัญหารักสามเส้า ชีวิตจะอาภัพผิดหวังในเรื่องของความรัก หย่าร้างกัน แต่งงานช้า ไม่ได้แต่งงาน ได้แต่งก็เลิกกัน ได้พ่อหม้าย เป็นเมียน้อย ระวังโรคผิวหนังทุกรูปแบบ'
  },
  {
      sum: 58,
      star: 1,
      result: 'มีผลรวมในระดับ ไม่ดี ชีวิตมักจะหลักลอย เป็นคนหลงผิดได้ง่าย ลืมตัว เอาแต่ใจตัวเอง เห็นกงจักรเป็นดอกบัว จะพาชีวิตสู่ความหายนะได้ มักกำพร้า และขาดที่พึ่ง ชีวิตจะมีแต่ความทุกข์ มีอุปสรรค ชีวิตสมรสไม่แน่นอน ครอบครัวแตกแยก รักสามเส้า มีเรื่องชู้สาว ให้ระวังอุบัติเหตุรถคว่ำ ถูกอาวุธถูกคนลอบทำร้าย มีปัญหาเรื่องสายตา ผ่าตัด โรคลำไส้ กระเพาะปัสสาวะอักเสบ โรคนิ่ว โรคไต'
  },
  {
      sum: 59,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก ส่งเสริมให้ประสบความสำเร็จอย่างดียิ่ง ขอบเรียนรู้อยู่ตลอดเวลา ชะตาชีวิตรุ่งเรืองดี ทำอาชีพการงานใดก็จะเจริญก้าวหน้าได้เร็วมาก คิดทำการค้าก็ร่ำรวย ดวงมีทรัพย์มีลาภยศ มักเลื่อมใสต่อสิ่งศักดิ์สิทธ์เชื่อถือในศาสนา ในความเร้นลับ รักความยุติธรรม เชื่อเรื่องวิญญาณชอบในพิธีกรรม และโชคลาภต่างๆ เป็นคนมีลางสังหรณ์ดีเยี่ยม ถ้าเป็นนักบวช ก็จะประสบความสำเร็จเรื่องพิธีกรรม อาคมแก่งกล้า ทำอะไรก็ขลัง จะทำให้ชีวิตประสบความสำเร็จอย่างมั่นคง มีสิ่งศักดิ์สิทธิ์ให้ความคุ้มครอง มีโอกาสได้ไปต่างประเทศ เที่ยวต่างประเทศ คบหากับคนต่างชาติต่างภาษา'
  },
  {
      sum: 59,
      star: 5,
      result: 'มีผลรวมในระดับ ดี เป็นคนมีเสน่ห์ ดึงดูดใจ ให้คนชอบพอรักใคร่ เป็นบุคคลที่มีความโชคดีเกี่ยวกับเรื่องเงินๆทองๆ จะสามารถหามาได้โดยง่าย ศุภโชค โภคทรัพย์ เข้ากับคนได้ทุกระดับ จะมีคนช่วยเหลือสนับสนุน เป็นเลขแห่งศิลป์และความเพ้อฝัน รักสวยรักงาม อารมณ์ปราณีต ละเอียดละมัย ชอบสถานที่โออ่า หรูหรา ดูดี ชะตาชีวิตโดยทั่วไปถือว่าดี ไม่ต้องต่อสู้ฟันฝ่ามากนัก มีความเป็นอยู่สุขสบาย มีชื่อเสียง ถ้าขยันทุ่มเทมากก็จะมั่งคั่งมาก'
  },
  {
      sum: 60,
      star: 4,
      result: 'มีผลรวมในระดับ ไม่ดี มีมุมหักเหได้ง่าย ชอบทำตัวหยิ่งจนคน ไม่ชอบหน้า จนสังคมไม่ยอมรับ บั้นปลายชีวิตจะตกต่ำมาก ถ้าเป็นสุภาพบุรษ ชีวิตครอบครัวจะแตกร้าว เลิกราหย่าร้าง คู่ครองล้มหายตายจากก่อนวัยอันควร สุภาพสตรีจะผิดหวังเรื่องความรักทุกรูปแบบ เช่น แต่งงานช้า ไม่ได้แต่ง ได้แต่งงานก็ต้องเลิก ได้พ่อหม้ายเป็นสามี เป็นเมียน้อย หมายเลข ๖๑ บวกกันได้เลข ๗ ดาวเสาร์ ดาวแห่งทุกข์ อุปสรรค อับโชค ติอสู้ด้วยความยากลำบาก หากชีวิตพบกับความสำเร็จที่ยิ่งใหญ่ มักจะต้องพบกับจุดจบอย่างไม่คาดคิด'
  },
  {
      sum: 62,
      star: 3,
      result: 'มีผลรวมในระดับ ปานกลาง หมายเลข ๖๒ รวมกันได้เลข ๘ ดาวราหูจะทำให้ชีวิตต้องตกอยู่ภายใต้สิ่งแวดล้อมที่ไม่ดี ถูกอิจฉาริษยา ถูกเอารัดเอาเปรียบ ถูกเบียดบังเอาความดีความชอบ ถูกกลั่นแกล้ง รังแก จะมีปัญหารักสามเส้า หากลุ่มหลงในสิ่งใดแล้ว มักจะไม่มีสติยั้งคิด มัวเมาในอารมณ์ หลงผิดได้ง่าย ท้ายที่สุดคือความหายนะ ชีวิตน่าจะประสบความสำเร็จ แต่ไม่ยั่งยืนถาวร ระวังโรค ตับอักเสบ ถุงน้ำดี เจ็บกล้ามเนื้อ เจ็บกระดูก'
  },
  {
      sum: 63,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก หมายเลข ๖๓ เป็นดาวคู่มิตรกัน ความรักสมหวังสดชื่น จะมีคนให้การอุปถัมภ์ค้ำจุนช่วยเหลือ สติปัญญาดี มีเสน่ห์ดึงดูดใจเพศตรงข้าม มีหัวคิดค้น มีมิตรสหายมาก ชีวิตประสบความสำเร็จอย่างดี สุภาพสตรีที่ตกอยู่ภายใต้อิทธิพลของหมายเลข ๖๓ จะมีโอกาสได้แต่งงานสูงมาก'
  },
  {
      sum: 64,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก มีทั้งด้านดีด้านเสีย ด้านดีดาวศุกร์และดาวพุธส่งเสริมหนุนนำให้มีความรุ่งเรืองและมีความสุข ดวงมีโชคมีวาสนา ฐานะความเป็นอยู่สุขสบาย เป็นคนมีรสนิยมสูง มีเสน่ห์ ไม่ชอบหยุดนิ่งอยู่กับที่ ชอบแสวงหาสิ่งใหม่ๆ ชีวิตพบกับความสำเร็จ ส่งผลให้ชีวิตดี ในด้านเสีย ชอบการเปลี่ยนแปลง ชอบแสวงหารสชาติให้กับชีวิต (มากรัก) แสวงหาคู่อยู่เรื่อยๆ หมายเลข ๖๔ บวกกันได้ ๑๐ จะทำให้ชีวิตมีความทุกข์ความผิดหวังแอบแฝงอยู่ บริวารในครอบครัวจะสร้างปัญหาให้ มีหนี้สินอย่างท่วมท้น มีโรคภัยไข้เจ็บเบียดเบียน'
  },
  {
      sum: 65,
      star: 5,
      result: 'มีผลรวมในระดับ ดีมาก เจ้าของชื่อมีดวงชะตาดี หนุนนำให้ชีวิตราบรื่นและมีความเจริญก้าวหน้า ด้านดีมีความสำเร็จอย่างดีเยี่ยม เป็นเลขคู่ทรัพย์คู่โชค ทำอะไรก็สะดวกสบาย ได้รับการสนับสนุนจากผู้ใหญ่ช่วยเหลืออย่างดี มั่นคงในอนาคต ถ้าบุคคลหมายเลข ๖๕ มีอิทธิพลของหมายเลข ๒๓ เข้ามาพัวพัน ถ้าพูดถึงความรักก็เป็นนักรักตัวฉกาจ หาตัวจับยาก ชะตาชีวิตรุ่งเรืองมีบุญพาวาสนาส่ง คิดทำสิ่งใดก็ดูจะสำเร็จง่ายดาย อุปสรรคน้อย มีผู้สนับสนุนอยู่เบื้องหลัง ความรักก็สดใสสวยงาม มีความสมปรารถนาน่าชื่นใจ'
  }
];

const CalculateLicensePlate = () => {
  const [plate, setPlate] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [sum, setSum] = useState(null);
  const canvasRef = useRef(null);

  const changeCharToNumber = (char) => {
    const charData = [
      { char: ["ก", "ด", "ถ", "ท", "ภ", "ฤ"], num: 1 },
      { char: ["ข", "ง", "ช", "บ", "ป"], num: 2 },
      { char: ["ฆ", "ฒ", "ต", "ฑ"], num: 3 },
      { char: ["ญ", "ธ", "ร", "ษ", "ค"], num: 4 },
      { char: ["ฉ", "ฌ", "ฎ", "ณ", "น", "ม", "ห", "ฬ", "ฮ"], num: 5 },
      { char: ["จ", "ล", "ว", "อ"], num: 6 },
      { char: ["ศ", "ส", "ซ"], num: 7 },
      { char: ["ผ", "พ", "ย", "ฝ", "ฟ"], num: 8 },
      { char: ["ฐ", "ฏ"], num: 9 }
    ];

    const foundIndex = charData.findIndex((item) => item.char.includes(char));
    return foundIndex === -1 ? 0 : charData[foundIndex].num;
  };

  const isNumber = (input) => {
    const regex = /^\d+$/;
    return regex.test(input);
  };

  const sumByPlate = (plate) => {
    const data = plate.split(" ");
    const charIndices = [];

    data.forEach((element) => {
      for (const char of element) {
        if (isNumber(char)) {
          charIndices.push(parseInt(char));
        } else {
          const count = changeCharToNumber(char);
          if (count !== 0) {
            charIndices.push(count);
          }
        }
      }
    });

    const sum = charIndices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sum;
  };

  const handleOnSearch = () => {
    if (plate === "") {
      return;
    }

    const sum = sumByPlate(plate);
    const foundStar = starData.find((item) => item.sum === sum);
    setSearchResult(foundStar ? foundStar.result : "ไม่พบข้อมูล");
    setSum(sum);
  };

  const handleExport = () => {
    const element = document.getElementById("plateResult");
    if (element) {
      html2canvas(element).then((canvas) => {
        const link = document.createElement("a");
        link.download = "plate_result.png";
        link.href = canvas.toDataURL();
        link.click();
      });
    } else {
      console.error("Element with id 'plateResult' not found.");
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const canvasDrawStar = (context, cx, cy, spikes, outerRadius, innerRadius) => {
    let rot = Math.PI / 2 * 3;
    let x = cx;
    let y = cy;
    let step = Math.PI / spikes;

    context.beginPath();
    context.moveTo(cx, cy - outerRadius);
    for (let i = 0; i < spikes; i++) {
      x = cx + Math.cos(rot) * outerRadius;
      y = cy + Math.sin(rot) * outerRadius;
      context.lineTo(x, y);
      rot += step;

      x = cx + Math.cos(rot) * innerRadius;
      y = cy + Math.sin(rot) * innerRadius;
      context.lineTo(x, y);
      rot += step;
    }
    context.lineTo(cx, cy - outerRadius);
    context.closePath();
    context.fillStyle = '#ffcc00';
    context.fill();
  };

  const drawStar = useCallback((context, cx, cy, size, count) => {
    for (let countStar = 0; countStar < count; countStar++) {
      const spikes = 5;
      const outerRadius = size;
      const innerRadius = size / 2.5;
      canvasDrawStar(context, countStar === 0 ? cx : cx + (45 * countStar), cy, spikes, outerRadius, innerRadius);
    }
  }, []);

  const drawImage = useCallback((plate, sum) => {
    clearCanvas();
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      const img = new Image();
      img.src = plateImage;
      img.onload = () => {
        context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

        const baseFontSize = 156;
        const fontSize = (baseFontSize * canvas.width) / 800;
        context.font = `normal ${fontSize}px SarunSthangluang`;

        const textWidth = context.measureText(plate).width;
        const textHeight = fontSize;

        const textY = (canvas.height + textHeight) / 2.2;

        const circleRadius = canvas.width * 0.04;
        const circleX = canvas.width - circleRadius - 50;
        const circleY = circleRadius + 30;

        context.beginPath();
        context.arc(circleX, circleY, circleRadius, 0, Math.PI * 2);
        context.fillStyle = 'red';
        context.fill();
        context.closePath();

        context.fillStyle = '#000';
        context.font = `normal ${fontSize}px SarunSthangluang`;

        const adjustedTextX = (canvas.width - textWidth) / 2;
        context.fillText(plate, adjustedTextX, textY - 50);

        context.fillStyle = '#fff';
        context.font = `normal ${fontSize / 4}px SarunSthangluang`;
        const sumTextWidth = context.measureText(`${sum}`).width;
        context.fillText(`${sum}`, circleX - sumTextWidth / 2, circleY + fontSize / 11);

        const starRadius = canvas.width * 0.03;
        const starX = starRadius + 50;
        const starY = starRadius + 30;

        const findIndexStar = starData.findIndex((element) => element.sum === sum);
        drawStar(context, starX, starY, starRadius, findIndexStar === -1 ? 0 : starData[findIndexStar].star);
      };
    }
  }, [drawStar]);

  useEffect(() => {
    if (plate === "") {
      clearCanvas();
      setSearchResult(null);
      setSum(null);
    } else if (sum !== null && plate !== "") {
      drawImage(plate, sum);
    }
  }, [plate, sum, drawImage]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleOnSearch();
    }
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-5 title">คำนวณผลรวมทะเบียนรถ</h2>
      <div className="input-container">
        <input
          type="text"
          placeholder="กรอกเลขทะเบียน"
          value={plate}
          onChange={(e) => setPlate(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button className="btn-danger" onClick={handleOnSearch}>คำนวณผลรวม</button>
      </div>
      {searchResult && (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card plate-result" id="plateResult">
              <div className="canvas-container">
                <canvas ref={canvasRef} width="800" height="400"></canvas>
              </div>
              <div className="card-body">
                <p>{searchResult}</p>
                <button className="btn-download" onClick={handleExport}>
                  Download ผลรวมทะเบียนรถ
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalculateLicensePlate;
