import React from 'react';

function ResultDisplay({ result }) {
  return (
    <div>
      <h2>ผลการคำนวณ</h2>
      <p>{result.text}</p>
      <img src={result.imageUrl} alt="ผลการคำนวณ" />
    </div>
  );
}

export default ResultDisplay;
