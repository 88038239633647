export const customerReviews = [
    {
      "name": "ป. ปริว",
      "location": "กรุงเทพมหานคร",
      "message": "บริการดีมากคะ ถึงรอเลขนานหน่อย แต่ก็ได้เลขที่เราชอบ ชัวร์คะ ⭐️⭐️⭐️⭐️⭐️"
    },
    {
      "name": "Noon Patcharaporn",
      "location": "ลพบุรี",
      "message": "บริการดีมากกก แนะนำเลยค่ะ สมการรอคอย❤️❤️❤️❤️"
    },
    {
      "name": "Somruethai Vanasupakul",
      "location": "กรุงเทพมหานคร",
      "message": "บริการดี สุภาพ ใจเย็น แนะนำว่าอะไรที่เหมาะกับเรา ที่สำคัญราคาไม่แรงค่ะ"
    },
    {
      "name": "Memol Matu",
      "location": "กรุงเทพมหานคร",
      "message": "บริการดี ประทับใจสุดๆคะ เป็นกันเอง ได้เลขที่ต้องการ ดูแลลูกค้าถึงบ้านเลย ไม่มีบวกเพิ่มใด ราคาไม่แพงเลยคะ สนใจอยากได้เลขทะเบียนสวย เลขมงคล สอบถามได้เลยคะ ✌️✌️"
    },
    {
      "name": "Naratip Kenny Sittisook",
      "location": "กรุงเทพมหานคร",
      "message": "ราคาจับต้องได้ บริการสุดหัวใจ เป็นยิ่งกว่าพ่อค้าลูกค้า งานเร็ว งานชัวร์ ดูแลกันไปยาวๆ ไม่มีโกงครับ แนะนำเลยครับ"
    },
    {
      "name": "Sarunna Supol",
      "location": "กรุงเทพมหานคร",
      "message": "ได้เลขสวยถูกใจ จองง่าย อธิบายทุกขั้นตอน แบบเข้าใจง่ายมากๆ แนะนำเลยค่ะ ^^~"
    },
    {
      "name": "Kanwara Dokduatawan",
      "location": "กรุงเทพมหานคร",
      "message": "ได้เลขสวยมากๆ ราคาคือไม่แพงเลย แต่รอนาน คุณแก้วบริการ ดูแลดีมาก"
    },
    {
      "name": "Mark Thanundorn",
      "location": "กรุงเทพมหานคร",
      "message": "บริการลูกค้าได้ดีมาเลยครับ ใส่ใจบริการทุกขั้นตอน ขอบคุณที่ให้คำปรึกษาเกี่ยวกับตัวเลขที่ต้องการดีมากๆเลยครับ😍"
    },
    {
      "name": "Monza Bewbe",
      "location": "นนทบุรี",
      "message": "บริการดีแนะนำให้ใช้บริการจองทะเบียนรถจากเพจนี้ค่ะ คุณแก้ว ดูแลดีมากๆ เลยค่ะ ^^"
    },
    {
      "name": "Nut Chaiburom",
      "location": "ขอนแก่น",
      "message": "ผมว่าการจองทะเบียนรถกับน้องแก้วเป็นอะไรที่มากกว่าการดูแลลูกค้าประทับใจทุกอย่างผ่านมาเกือบสี่เดือนกว่าจะได้เลขที่เราจอง น้องเขาสู้ให้เรามาตลอดประทับใจมากครับ ขอบคุณมากนะครับ👍"
    }
];
