import React, { useState } from 'react';

function CalculateForm({ onResult }) {
  const [licenseNumber, setLicenseNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/calculate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ licenseNumber }),
    });

    const data = await response.json();
    onResult(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={licenseNumber}
        onChange={(e) => setLicenseNumber(e.target.value)}
        placeholder="กรุณาพิมพ์เลขทะเบียนที่ต้องการคำนวณ"
      />
      <button type="submit">คำนวณ</button>
    </form>
  );
}

export default CalculateForm;
